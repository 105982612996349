export const GA_TRACKING_ID = 'G-JGM96E6QKV'; // Replace with your tracking ID

// Log the pageview with their URL
export const pageview = (url:string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// Log specific events
export const event = ({ action, params }: any) => {
  window.gtag('event', action, params);
};