import { AppProps } from "next/app";
import { DarkModeProvider } from '@/hooks/useTheme';
import { appWithTranslation } from 'next-i18next'
import { ToastContainer } from 'react-toastify'

import "../styles/globals.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { pageview } from "@/services/analytics";
import CookieConsent from "react-cookie-consent";
import Link from "next/link";

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url:string) => {
      pageview(url);
    };

    // Track initial pageview
    pageview(window.location.pathname);

    // Monitor route changes for subsequent pageviews
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);


  return (
    // <div>hello</div>
    <DarkModeProvider>
      <Component {...pageProps} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <CookieConsent
          location="bottom"
          buttonText="Accept Cookies"
          declineButtonText="Decline"
          cookieName="siteCookieConsent"
          style={{ background: "#fff", color: "#2084F9" }} // Custom colors matching typical brand colors
          buttonStyle={{ color: "#fff", fontSize: "16px", background: "#f57c00", borderRadius: 6 }} // Button styling to stand out
          expires={365} // Setting cookie to expire after one year, adjust per your requirements
          enableDeclineButton
          declineButtonStyle={{ color: "#666", fontSize: "16px", background: "#fff",  borderRadius: 6, }}
        >
          We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.
          <span style={{ marginLeft: "12px" }}>
            <Link href="/privacy_policy" className="underline" >Learn more</Link>
          </span>
        </CookieConsent>
    </DarkModeProvider>
  );
}

export default appWithTranslation(App)